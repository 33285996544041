<template>
  <div class="modal-temp-review">
    <sweet-modal
      ref="modal"
      overlay-theme="dark"
      :title="reviewType === 'cs' ? '상담후기 등록' : '제작후기 등록'"
      @close="onClose"
    >
      <div class="trial-modal-content">
        <div class="step step2">
          <div class="rate-wrapper">
            <div class="step-label">
              {{ reviewType === 'cs' ? '이 제작사와의 상담이 마음에 드셨나요?' : '제작 후기를 남겨주세요' }}
            </div>
            <star-rating
              :rating="value.rate"
              @rating-selected="(res) => (value.rate = res)"
              active-color="#ff5722"
              :star-size="32"
              :increment="0.5"
              :padding="5"
              :star-points="[
                20,
                34,
                30,
                40,
                31,
                40,
                32,
                40,
                33,
                38,
                33,
                37,
                31,
                25,
                39,
                18,
                40,
                16,
                40,
                15,
                39,
                14,
                38,
                13,
                27,
                12,
                22,
                1,
                21,
                0,
                20,
                0,
                19,
                0,
                18,
                1,
                13,
                12,
                2,
                13,
                1,
                14,
                0,
                15,
                0,
                16,
                1,
                18,
                9,
                25,
                7,
                37,
                7,
                38,
                8,
                40,
                9,
                40,
                10,
                40,
                20,
                34,
              ]"
              :show-rating="false"
            ></star-rating>
          </div>
          <template v-if="reviewType !== 'cs'">
            <div class="review-img-wrapper">
              <input-img-uploader-array
                :value.sync="value.imgs"
                :width="100"
                :height="100"
                :limit="10"
                :countLabel="true"
              ></input-img-uploader-array>
            </div>
          </template>
          <div class="review-content-wrapper">
            <div class="position-relative textarea-container">
              <textarea
                class="textarea"
                rows="5"
                placeholder="후기를 입력해주세요."
                v-model="value.content"
                :maxLength="5000"
                @keyup="(e) => (value.content = e.target.value)"
              ></textarea>
              <div class="textarea-length">
                <span>{{ value.content.length }} / 5000</span>
              </div>
            </div>
          </div>

          <div class="button-group">
            <button class="button is-dark body2-bold" @click="clickReview">
              {{ reviewType === 'cs' ? '상담' : '제작' }}후기 등록
            </button>
          </div>
        </div>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating/src/star-rating';
import { SweetModal } from 'sweet-modal-vue';
import InputImgUploaderArray from '../module/InputImgUploaderArray';
import UserAPIMixin from '../../mixins/UserAPIMixin';
export default {
  name: 'TrialReviewModal',
  mixins: [UserAPIMixin],
  components: {
    InputImgUploaderArray,
    SweetModal,
    StarRating,
  },
  props: {
    product: {
      type: Object,
    },
    reviewType: {
      type: String,
      default: 'cs',
      // cs: 상담
      // pd: 제작
    },
  },
  created() {},
  data() {
    return {
      step: 1,
      value: {
        rate: 0,
        content: '',
        imgs: [],
      },
    };
  },
  methods: {
    open() {
      // this.setAuth();
      this.$refs.modal.open();
    },
    onClose() {
      this.$emit('close');
    },
    valid() {
      let msg = '';
      if (this.value.content.trim() === '') {
        msg = '내용을 입력해주세요.';
      }
      if (msg !== '') {
        this.toast(msg);
      }

      return msg === '';
    },
    clickReview() {
      if (!this.valid()) return;
      let param = this.cloneItem(this.value);
      param.product_id = this.product.product.id;
      param.imgs = param.imgs.filter((i) => i !== '');
      param.review_type = this.reviewType === 'cs' ? 1 : 2;
      param.inquiry_id = this.product.id;
      param.title = param.content.length > 15 ? param.content.substring(0, 15) + '...' : param.content;
      this.$store.commit('setLoading', true);
      this.request.user
        .post('/product/review', param)
        .then((res) => {
          this.$store.commit('setLoading', false);
          if (res.status === 200) {
            this.toast(`${this.reviewType === 'cs' ? '상담' : '제작'}후기가 등록되었습니다.`);
            this.clickDone();
          }
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit('setLoading', false);
        });
    },
    clickDone() {
      this.$refs.modal.close();
      this.$emit('done');
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.step
  display flex
  align-items center
  flex-direction column
  gap 32px

.step1
  .img-product
    height 80px

.step2
  .step-label
    font-size 14px
    margin-bottom 8px
    color #4f4f4f
  textarea
    width 100%
    height 120px
    border 1px solid #e3e3e3
    border-radius 8px
    padding 12px 16px

.rate-wrapper
  display flex
  flex-direction column
  width 100%

.review-content-wrapper
  display flex
  flex-direction column
  width 100%

.review-img-wrapper
  display flex
  flex-direction column
  width 100%

.button-group
  display grid
  grid-gap 8px
  .button
    width 300px
    height 52px !important

.textarea-container
  position relative
  display flex
  align-items center
  width 100%
  border-radius 8px


.textarea-length
  position absolute
  right 16px
  bottom 12px
  font-size 12px
  color $sub4
</style>

<style lang="stylus">
.modal-temp-review
  .sweet-modal
    width 600px !important

  .sweet-modal .sweet-content
    padding 24px !important

@media (max-width: 425px)
  .modal-temp-review
    .sweet-modal
      width 100% !important
      border-radius 0 !important
</style>
