<template>
  <div v-if="product" class="modal-production-req">
    <sweet-modal ref="modal" overlay-theme="dark" title="제작 요청서" @close="onClose">
      <div class="production-req-modal-content">
        <div class="inquiry-request-item">
          <div class="subtitle7 sub3">서비스 이름</div>
          <div class="body4-medium main">{{ product.service_name }}</div>
        </div>
        <div class="inquiry-request-item">
          <div class="subtitle7 sub3">서비스 한 줄 소개</div>
          <div class="body4-medium main" style="white-space: pre-line; word-break: keep-all">
            {{ product.simple_desc }}
          </div>
        </div>
        <div class="inquiry-request-item">
          <div class="subtitle7 sub3">서비스 소개</div>
          <div class="body4-medium main" style="white-space: pre-line; word-break: keep-all">
            {{ product.info }}
          </div>
        </div>
        <!-- <div class="inquiry-request-item">
          <div class="subtitle7 sub3">제작옵션</div>
          <div class="body4-medium main">
            {{ optionKor(product.option) }}
          </div>
        </div> -->
        <div class="inquiry-request-item">
          <div class="subtitle7 sub3">제작예산</div>
          <div class="body4-medium main">
            {{ setBudget(product.budget) }}
          </div>
        </div>
        <div class="inquiry-request-item">
          <div class="subtitle7 sub3">희망 소요기간</div>
          <div class="body4-medium main">{{ product.period }}개월</div>
        </div>
        <div v-if="product.files && product.files.length > 0" class="inquiry-request-item">
          <div class="subtitle7 sub3">참고 자료</div>
          <a
            v-for="(file, idx) in product.files"
            :key="`req-file-${idx}`"
            class="inquiry-request-item-file body4-medium main"
            :href="file"
            target="_blank">
            <img src="/static/icon/u_file-s.svg" alt="" style="width: 20px; height: 20px" />
            <div class="body3 sub">{{ file.split('/').pop() }}</div>
          </a>
        </div>
        <div class="inquiry-request-item">
          <div class="subtitle7 sub3">서비스 지원환경</div>
          <div class="body4-medium main">{{ envKor(product.env) }}</div>
        </div>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue';
export default {
  name: 'ProductionReqModal',
  mixins: [],
  components: {
    SweetModal,
  },
  props: {
    product: {
      type: Object,
    },
  },
  created() {},
  data() {
    return {};
  },
  methods: {
    open() {
      this.$refs.modal.open();
      document.documentElement.style.overflow = 'hidden'; // HTML overflow를 hidden으로 설정
    },
    onClose() {
      document.documentElement.style.overflow = ''; // HTML overflow를 원래대로 복원
      this.$emit('close');
    },
    setBudget(budget) {
      return (budget * 10000).toLocaleString('ko-KR') + '원';
    },
    optionKor(option) {
      return {
        0: '템플릿 그대로 제작',
        1: '템플릿을 바탕으로 수정 제작',
      }[option];
    },
    envKor(env) {
      return {
        0: 'Mobile Web 단독\n(PC로 접속시 Mobile Web 뷰 제공)',
        1: 'PC Web + Mobile Web',
        2: 'PC Web + Mobile Web + Mobile App',
        3: 'Mobile App',
      }[env];
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'

.production-req-modal-content
  display flex
  flex-direction column
  gap 24px
  .inquiry-request-item
    display flex
    flex-direction column
    gap 2px
    .inquiry-request-item-file
      display flex
      align-items center
      gap 8px
      padding 8px
      border-radius 8px
      border 1px solid $gray1
</style>

<style lang="stylus">
.modal-production-req
  .sweet-modal
    width 343px !important

  .sweet-modal .sweet-content
    padding 16px !important

  .sweet-modal .sweet-title
    border-bottom none !important
    height 40px !important
    padding 16px 16px 0 !important

  .sweet-modal .sweet-box-actions .sweet-action-close
    width 24px !important
    height 24px !important
    font-size 24px !important
    line-height 24px !important
  .sweet-modal .sweet-title > h2
    font-size 18px !important
    font-weight 700 !important
    line-height 24px !important

@media (max-width: 425px)
  .modal-production-req
    .sweet-modal
      width 100% !important
      border-radius 0 !important
</style>
