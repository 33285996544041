<template>
  <div class="my-template-manufacture-inquiry">
    <div class="container">
      <div class="tabs">
        <div
          class="tab unselect"
          v-for="(tab, index) in tabs"
          :key="`tab-${index}`"
          @click="clickTab(tab.value)"
          :class="{ active: selectedStatus === tab.value }"
        >
          {{ tab.label }}
        </div>
      </div>
      <div class="list">
        <card-template-manu-facture
          v-for="(item, index) in list"
          :key="`list-${index}`"
          :card="item"
          @openReviewModal="openReviewModal(item)"
          @openProductionReqModal="openProductionReqModal(item)"
        />
      </div>
    </div>
    <temp-review-modal
      v-if="vTempReviewModal"
      ref="tempReviewModal"
      review-type="cs"
      :product="selectedProduct"
      @close="closeReviewModal"
      @done="doneReviewModal"
    ></temp-review-modal>
    <production-req-modal ref="productionReqModal" :product="selectedProduct" />
  </div>
</template>
<script>
import CardTemplateManuFacture from '@/components/component/CardTemplateManuFacture.vue';
import UserAPIMixin from '../../mixins/UserAPIMixin';
import PartnerAPIMixin from '@/mixins/PartnerAPIMixin';
import TempReviewModal from '../modal/TempReviewModal';
import ProductionReqModal from '../modal/ProductionReqModal.vue';
const io = require('socket.io-client');

export default {
  name: 'MyTemplateManufactureInquiry',
  components: {
    CardTemplateManuFacture,
    TempReviewModal,
    ProductionReqModal,
  },
  mixins: [UserAPIMixin, PartnerAPIMixin],
  props: {},
  data() {
    return {
      tabs: [
        {
          label: '전체',
          value: '',
        },
        {
          label: '상담중',
          value: 1,
        },
        {
          label: '상담완료',
          value: 2,
        },
        {
          label: '계약확정',
          value: 3,
        },
      ],
      selectedStatus: '',
      list: [],
      page: {
        cur_page: 1,
        total_page: 1,
      },
      selectedProduct: undefined,
      vTempReviewModal: false,
      vProductionReqModal: false,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.setSocketIO();
    this.getData();
  },
  mounted() {},
  methods: {
    openProductionReqModal(item) {
      this.selectedProduct = item;
      this.$nextTick(() => {
        this.$refs.productionReqModal.open();
      });
    },
    openReviewModal(item) {
      this.selectedProduct = item;
      this.vTempReviewModal = true;
      this.$nextTick(() => {
        this.$refs.tempReviewModal.open(item);
      });
    },
    closeReviewModal() {
      setTimeout(() => {
        this.vTempReviewModal = false;
      }, 300);
    },
    doneReviewModal() {
      this.getData();
      setTimeout(() => {
        this.vTempReviewModal = false;
      }, 300);
    },
    setSocketIO() {
      this.socket = io.connect('https://master-dev.launchpack.co.kr:3000');
      setTimeout(() => {
        this.socket.on('message', (msg) => {
          if (msg.type === 'template') {
            this.list.find((item) => item.id === msg.message.inquiry_id).message_count++;
          }
        });
      }, 500);
    },
    getData() {
      let params = {
        cur_page: this.page.cur_page,
      };
      if (this.selectedStatus) {
        params.status = this.selectedStatus;
      }
      if (this.page.cur_page > this.page.total_page) {
        return;
      }
      if (this.$route.path.includes('partner')) {
        this.request.partner.get('launchpack/template_manufacture_inquiry', { params: params }).then((res) => {
          this.list = res.data.data;
          this.page.cur_page = res.data.cur_page;
          this.page.total_page = res.data.total_page;
        });
      } else {
        this.request.user.get('launchpack/template_manufacture_inquiry', { params: params }).then((res) => {
          this.list = res.data.data;
          this.page.cur_page = res.data.cur_page;
          this.page.total_page = res.data.total_page;
        });
      }
    },
    clickTab(value) {
      this.selectedStatus = value;
      this.page.cur_page = 1;
      this.getData();
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~assets/css/lp_main'
  .my-template-manufacture-inquiry
    display flex
    justify-content center
    .container
      width 1152px
      .tabs
        display flex
        gap 8px
        .tab
          padding 7px 14px
          border-radius 50px
          border 1px solid $gray
          background-color white
          font-size 14px
          font-weight 500
          &.active
            background-color $main
            color white
      .list
        margin-top 24px
        display flex
        flex-direction column
        gap 12px
</style>
